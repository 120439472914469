// Local dependencies
import { defaultInputStyle } from '@shared/ui/form/FormStyles';

export const validateUsername = (username, setUserName) => {
  let clean = username.trim().replace(/[^\d]/g, '');

  let formattedPhone = '';

  let len = clean.length;

  switch (true) {
    case len < 1:
      formattedPhone = '';
      break;

    case len <= 3:
      formattedPhone = `(${clean}`;
      break;

    case len > 3 && len < 7:
      formattedPhone = `(${clean.substring(0, 3)}) ${clean.substring(3, 6)}`;
      break;

    case len > 6 && len < 10:
      formattedPhone = `(${clean.substring(0, 3)}) ${clean.substring(
        3,
        6,
      )}-${clean.substring(6, 9)}`;
      break;

    default:
      const digits = clean.substring(3, 12);
      clean = clean.substring(3, 12);

      formattedPhone = `(${digits.substring(0, 3)}) ${digits.substring(
        3,
        6,
      )}-${digits.substring(6, 9)}`;
  }

  setUserName({ formatted: formattedPhone, onlyNumbers: clean });
};

export const phoneCodeStyles = {
  ...defaultInputStyle,
  p: { l: '3.6rem', r: '1.3rem' },
};

export const phoneCodeKG = '+996';

export const loadingScripts = (setValue) => {
  if (typeof document !== 'undefined') {
    const scriptElements = document.scripts;

    for (let i = 0; i < scriptElements.length; i++) {
      if (scriptElements[i].src.includes('recaptcha')) {
        setValue(true);
      }
    }
  }
};
