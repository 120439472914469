/* External dependencies */
import { Div } from 'atomize';
import { graphql } from 'gatsby';
import React from 'react';

/* Local dependencies */
import LoginForm from '../../entities/authentication/ui/LoginForm';
import MedcheckLogoWithSmile from '../../svg/MedcheckLogoWithSmile';

export default function Login() {
  return (
    <Div
      d="flex"
      align="center"
      flexDir="column"
      justify="center"
      bg="#E5E5E5"
      h="100vh"
    >
      <MedcheckLogoWithSmile height={50} width={500} />
      <LoginForm />
    </Div>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
