/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import { navigate } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import ReCaptcha from 'react-google-recaptcha';
import * as ReactRedux from 'react-redux';

/* Local dependencies */
import { RootState } from '@app/redux/store';
import { getDefaultPath } from '@app/routes/getDefaultPath';
import Button from '@shared/ui/button';
import FormField from '@shared/ui/form/FormField';
import { formStyle, wrapStyle } from '@shared/ui/form/FormStyles';
import Popup from '@shared/ui/popup/Popup';
import Spinner from '@shared/ui/spinner/Spinner';
import Layout from '../../../shared/ui/layout/WebLayout';
import { initClient, login } from '../redux/actions';
import {
  loadingScripts,
  phoneCodeKG,
  phoneCodeStyles,
  validateUsername,
} from '../utils/helper';

export default function LoginForm() {
  const { t } = useTranslation();
  const dispatch = ReactRedux.useDispatch();

  const [username, setUsername] = useState({ formatted: '', onlyNumbers: '' });
  const { client, currentUser, error, loading, initializing } =
    ReactRedux.useSelector((store: RootState) => store.login);
  const [userLoading, setUserLoading] = useState(true);
  const labelPhone = t('phone');
  const phonePlaceholder = t('phonePlaceholder');
  const sentSMSCode = t('sentSMSCode');
  const isInvalid = !username.onlyNumbers;
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [verifyCaptcha, setVerifyCaptcha] = useState({ type: 'non-active' });
  const SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    const logScripts = () => {
      const scriptElements = document.scripts;
      for (let i = 0; i < scriptElements.length; i++) {
        if (scriptElements[i].src.includes('recaptcha')) {
          setRecaptchaLoaded(true);
        }
      }
    };

    logScripts();

    if (!client) {
      dispatch(initClient());
    }
  }, []);

  useEffect(() => {
    if (!initializing) {
      setUserLoading(false);
    }
  }, [initializing]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadingScripts(setRecaptchaLoaded);
    }, 100);

    if (recaptchaLoaded) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [recaptchaLoaded]);

  if (client) {
    navigate(getDefaultPath(currentUser));
  }

  function onSubmit(e) {
    e.preventDefault();

    if (
      verifyCaptcha.type === 'non-active' ||
      verifyCaptcha.type === 'not-verified'
    ) {
      setVerifyCaptcha({ type: 'not-verified' });
      return;
    }

    const correctUsername = (phoneCodeKG + username.onlyNumbers).trim();

    dispatch(login(correctUsername));
  }

  if (initializing || client || userLoading) {
    return (
      <Popup>
        <Div d="flex" align="center" justify="center" w="300px" h="60px">
          <Spinner />
        </Div>
      </Popup>
    );
  }

  return (
    <Layout>
      <Div {...wrapStyle} style={{ width: 'inherit' }} m={{ t: '3rem' }}>
        <form onSubmit={onSubmit} style={formStyle}>
          <Row m="0">
            <Col
              border={{ b: '1px solid' }}
              textAlign="left"
              borderColor="basic_border_color"
            >
              <Text tag="h1" textSize="18px" textWeight="bold" m="1rem">
                {<Trans>loginSignInWithSMS</Trans>}
              </Text>
            </Col>
          </Row>
          <Row p="1rem" m="0">
            <FormField
              disabled={loading}
              fieldDescription={sentSMSCode}
              label={labelPhone}
              inputValue={username.formatted}
              inputType="tel"
              inputName="username"
              onChange={(e) => validateUsername(e.target.value, setUsername)}
              placeholder={phonePlaceholder}
              required={true}
              inputStyle={phoneCodeStyles}
              prefix={
                <Text
                  textColor="table_cell_text_color"
                  textSize="body"
                  textWeight="600"
                  pos="absolute"
                  top="51.5%"
                  left="0.7rem"
                  transform="translateY(-50%)"
                >
                  {phoneCodeKG}
                </Text>
              }
            />
            {error && (
              <Col>
                <Text textColor="red">{<Trans>{error.message}</Trans>}</Text>
              </Col>
            )}
          </Row>
          <Row p="1rem" m="0" h="150px">
            <ReCaptcha
              sitekey={SITE_KEY}
              onChange={(token) => setVerifyCaptcha(token)}
            />
            {verifyCaptcha.type === 'not-verified' && (
              <Text p={{ y: '5px' }} textColor="red">
                <Trans>completeCaptcha</Trans>
              </Text>
            )}
            {!recaptchaLoaded && <Spinner />}
          </Row>
          <Row
            m="0"
            border={{ t: '1px solid' }}
            borderColor="basic_border_color"
            p="1rem"
          >
            <Col d="flex" justify="flex-end">
              <Button
                typeColor="primary"
                disabled={isInvalid || loading}
                loading={loading}
                style={{ borderColor: 'btn_primary_bg' }}
              >
                {<Trans>getSMSCode</Trans>}
              </Button>
            </Col>
          </Row>
        </form>
      </Div>
    </Layout>
  );
}
