/* External dependecies */
import { Div, Icon } from 'atomize';
import React from 'react';

export default function Spinner() {
  const minHeight = '40px';

  return (
    <Div
      className="loading"
      pos="absolute"
      top="60%"
      left="50%"
      right="50%"
      style={{
        transform: 'translate(-50%, -50%)',
        zIndex: 11,
      }}
      w="100%"
      h="100%"
      d="flex"
      justify="center"
      align="center"
    >
      <Div className="loading__icon"></Div>
    </Div>
  );
}
