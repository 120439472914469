/* External dependencies */
import { Div, Modal, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

interface PopupProps {
  children: React.ReactNode;
  onClose?: () => void;
  title: string;
  headerColor?: string;
  maxW?: string;
}

export default function Popup({
  title,
  onClose,
  children,
  headerColor,
  maxW = '400px',
}: PopupProps) {
  const headerSize = '18px';

  return (
    <Modal
      align="center"
      onClose={onClose}
      rounded="sm"
      w="auto"
      maxW={maxW}
      p="10px"
      isOpen={true}
      className="popup"
    >
      <Text
        tag="h1"
        textAlign="left"
        textSize={headerSize}
        textColor="grey"
        p={{ x: '1.5rem', y: '1rem' }}
      >
        <Trans>{title}</Trans>
      </Text>
      <Div border="1px solid" borderColor="border_color">
        {children}
      </Div>
    </Modal>
  );
}
